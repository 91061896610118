<template>
    <main class="main-container">
        
        <div class="wrapper text-center">
            <div class="text-element">
                <h1>Hey!</h1>
                <h3>My name is HiddenStorm aka Mark and im a hobby programmer/developer using Python and C# and im learning a bunch of other languages such as PHP, JavaScript and HTML.</h3>
                <p>Some of my projects I made were meant to be published but other than a discord server and github I didnt have a place to do that so I decided to work on my own website! So, this is it, this is where i present and publish most of my Projects including the docs for my librarys and all the links such as my github. If you have any suggestions or questions regarding one of my projects you can contact me in a variety of different ways listed in the "Links" tab on the left.</p>
            </div>

            <div class="text-element">
                <h1>Enjoy!</h1>
                <p>Note that this website is still under the developement so bugs are very much expected.</p>
            </div>
        </div>

    </main>
</template>

<script>
export default {
    name: 'Main'
}
</script>
