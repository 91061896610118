import { createRouter, createWebHashHistory } from 'vue-router'
import Main from '../views/Main.vue'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/hiddencord',
    name: 'HiddenCord',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hiddencord" */ '../views/HiddenCord.vue')
  },
  {
    path: '/discapi',
    name: 'DiscAPI',
    component: () => import(/* webpackChunkName: "discapi" */ '../views/DiscAPI.vue')
  },
  {
    path: '/links',
    name: 'Links',
    component: () => import(/* webpackChunkName: "links" */ '../views/Links.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
