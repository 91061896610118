<template>
    <header class="relative bg-indigo-700 text-gray-200 shadow-xl flex justify-between items-center z-20">
        <div class="header-item" @click="toggleNav()">
            <i class="fas fa-bars"></i>
        </div>
        <div class="header-item">
            <a href="/">HiddenStorm.de</a>
        </div>
    </header>

    <transition name="slide">
        <nav v-if="navActivated" class="fixed z-40 bg-indigo-700 top-0 left-0 w-80 max-w-full text-gray-200 h-screen overflow-auto">
            <div class="nav-item" @click="toggleNav()">
                <i class="fas fa-times"></i>
            </div>
            <router-link to="/">
                <div class="nav-item" @click="toggleNav()">
                    Main
                </div>
            </router-link>
            <router-link to="/discapi">
                <div class="nav-item" @click="toggleNav()">
                    DiscAPI
                </div>
            </router-link>
            <router-link to="/hiddencord">
                <div class="nav-item" @click="toggleNav()">
                    HiddenCord
                </div>
            </router-link>
            <router-link to="/links">
                <div class="nav-item" @click="toggleNav()">
                    Links
                </div>
            </router-link>
        </nav>
    </transition>

    <transition name="fade">
        <div class="nav-background bg-gray-900 bg-opacity-50 fixed top-0 bottom-0 left-0 right-0 z-30" v-if="navActivated" @click="toggleNav()"></div>
    </transition>
</template>

<script>
export default {
    name: 'Navbar',
    data() {
        return {
            navActivated: false
        }
    },
    methods: {
        toggleNav() {
            if (this.navActivated === true) {
                this.navActivated = false;
            } else {
                this.navActivated = true;
            }
        }
    }
}
</script>

<style>

.header-item {
    @apply p-4 text-xl cursor-pointer hover:bg-indigo-800 transition-colors;
}

.nav-item {
    @apply py-4 px-5 text-xl cursor-pointer hover:bg-indigo-800 transition-colors;
}



.slide-enter-from,
.slide-leave-to {
    @apply transform -translate-x-full;
}

.slide-enter-active,
.slide-leave-active {
    @apply transition-all;
}

.fade-enter-from,
.fade-leave-to {
    @apply opacity-0;
}

.fade-enter-active,
.fade-leave-active {
    @apply transition-all;
}

</style>