<template>
  <div class="bg-gray-700 min-h-screen text-gray-200 relative">
    <Navbar />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-container {
  @apply relative z-0 mt-10 pb-16;
}

.wrapper {
    width: 1200px;
    margin: 0 auto;
    max-width: 100%;
    padding: 12px;
}

.text-element + .text-element {
    @apply mt-12 border-t-2 border-gray-500 pt-12;
}

.text-element h1 {
    @apply text-4xl font-bold mb-6 text-indigo-200;
}

.text-element h3 {
    @apply text-xl font-semibold mb-6 text-indigo-200;
}

.text-element p {
    @apply mb-4;
}


</style>
